import AssignmentIcon from "@mui/icons-material/Assignment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getAvatarUrl, numWithComma } from "../../utils/common-functions";
import { IsSwopStakeWeb } from "../../services/ls";
import { Link, useLocation } from "react-router-dom";
import { updateBadge } from "../../redux/slices/notification.slice";

function AccountMenu({
  onProfileClick,
  onActivityClick,
  onFollowingClick,
  onLogoutClick,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { response: playerDetail } = useSelector(
    (state) => state.user.playerSummary
  );
  const { systemSettings } = useSelector((state) => state.systemSettings);
  const { unseenNotification, updateSeen } = useSelector(
    (state) => state.notification.badge
  );

  let playerFullName = "";
  if (playerDetail?.firstName || playerDetail?.lastName) {
    playerFullName = `${
      playerDetail.firstName ? playerDetail.firstName : ""
    }, ${playerDetail.lastName ? playerDetail.lastName[0] : ""}`;
  } else {
    playerFullName = playerDetail ? playerDetail.username : "";
  }

  const playerName = IsSwopStakeWeb()
    ? playerFullName
    : playerDetail?.username ?? "";

  const ftpCredits =
    playerDetail &&
    playerDetail.playerWallets &&
    playerDetail.playerWallets.find((i) => i.WalletTypeId === 2)
      ? playerDetail.playerWallets.find((i) => i.WalletTypeId === 2).Balance
      : 0;

  const jackpotWalletCount =
    playerDetail &&
    playerDetail.playerWallets &&
    playerDetail.playerWallets.filter(
      (w) => w.WalletTypeId === 3 && w.Balance > 0
    ).length;

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "background.dark",
        color: "text.contrastText",
        borderRadius: "5px 5px 0px 0px",
      }}
    >
      <Box sx={{ px: 2, pt: 2, pb: 1 }}>
        <Stack spacing={2} direction="row" alignItems="center">
          {playerDetail && (
            <Avatar
              src={getAvatarUrl(playerDetail.avatar)}
              alt={playerDetail.username}
            />
          )}

          <Box>
            <Typography variant="h6" component="p">
              {playerName}
              {/* Jimmy P, */}
            </Typography>
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: (theme) => `${theme.palette.text.contrastText}AA`,
              }}
            >
              Welcome to SwopStakes
            </Typography>
          </Box>
        </Stack>
        <Divider light sx={{ my: 1 }} />

        <Box
          sx={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-around",
            px: 1,
          }}
        >
          <Box sx={{ mr: 2 }}>
            <Typography
              variant="body1"
              component="p"
              fontWeight="500"
              gutterBottom
            >
              FreePlay Credits
            </Typography>
            <Typography variant="body2" component="p">
              {numWithComma(`${ftpCredits}`)}
              {/* 1,000 */}
            </Typography>
          </Box>
          {systemSettings?.ptpEnabled && (
            <Box>
              <Typography
                variant="body1"
                component="p"
                fontWeight="500"
                gutterBottom
              >
                Jackpot Wallets
              </Typography>
              <Typography
                variant="body2"
                component="p"
                sx={{
                  lineHeight: 1.8,
                  bgcolor: "rgb(255, 255, 255, 0.2)",
                  borderRadius: 1,
                }}
              >
                {numWithComma(`${jackpotWalletCount}`)}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.dark",
        }}
      >
        <Link
          to="/notifications"
          state={{ from: location.pathname }}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton onClick={onActivityClick} variant="light">
            <ListItemIcon>
              <NotificationsIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <span>Notifications</span>
                  {!updateSeen && unseenNotification > 0 && (
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        bgcolor: "secondary.main",
                        color: "white",
                        borderRadius: "100px",
                        minWidth: 24,
                        minHeight: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 16,
                        lineHeight: 1,
                        fontWeight: "500",
                        ml: 2,
                        padding: "4px 2px",
                      }}
                    >
                      {unseenNotification}
                    </Box>
                  )}
                </Box>
              }
            />
            <ChevronRightIcon />
          </ListItemButton>
        </Link>
        <ListItemButton onClick={onActivityClick} variant="light">
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary="My Activity" />
          <ChevronRightIcon />
        </ListItemButton>
        <ListItemButton onClick={onFollowingClick} variant="light">
          <ListItemIcon>
            <GroupsIcon />
          </ListItemIcon>
          <ListItemText primary="Following" />
          <ChevronRightIcon />
        </ListItemButton>
        <ListItemButton onClick={onProfileClick} variant="light">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" />
          <ChevronRightIcon />
        </ListItemButton>
        {/* <ListSubheader sx={{ color: "white", opacity: 0.6 }}>
          Support
        </ListSubheader> */}
        {/* <ListItemButton variant="light">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="How to Play" />
          <ChevronRightIcon />
        </ListItemButton> */}
        {/* <ListItemButton variant="light">
          <ListItemIcon>
            <SupportAgentIcon />
          </ListItemIcon>
          <ListItemText primary="Help" />
          <ChevronRightIcon />
        </ListItemButton> */}
        {IsSwopStakeWeb() && (
          <ListItemButton onClick={onLogoutClick} variant="light">
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        )}
      </List>
    </Box>
  );
}

AccountMenu.propTypes = {
  onProfileClick: PropTypes.func.isRequired,
  onActivityClick: PropTypes.func.isRequired,
  onFollowingClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default AccountMenu;
