import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHomeGames } from "@/redux/slices/gameHome.slice";
import { IsAuthenticated, IsSwopStakeWeb } from "@/services/ls";

export function useHomePage() {
  const dispatch = useDispatch();
  const gameHome = useSelector((state) => state.gameHome);

  const [popularCategory, setPopularCategory] = useState("New");

  const isLoggedIn = IsAuthenticated();
  const isSwopstakesWeb = IsSwopStakeWeb();

  useEffect(() => {
    dispatch(getHomeGames());
  }, []);

  /**
   * Set popular category to "My Games" if user has player games
   */
  // useEffect(() => {
  //   if (gameHome.gamePlayer && gameHome.gamePlayer.length > 0) {
  //     setPopularCategory("My Games");
  //   }
  // }, [gameHome]);

  const getGames = () => {
    const gameNonPlayer = gameHome.gameNonPlayer.filter((ps) => {
      if (!isLoggedIn && !isSwopstakesWeb) return ps.freeToPlay === false;
      return true;
    });

    const allgames = [...gameHome.gamePlayer, ...gameNonPlayer];

    if (popularCategory === "My Games") return gameHome.gamePlayer;
    if (popularCategory === "New") return gameHome.gameNonPlayer;
    if (popularCategory === "All") return allgames;

    return allgames.filter((ps) => {
      if (popularCategory === "Racing") return ps.eventTypeId <= 3;
      if (popularCategory === "Sports") return ps.eventTypeId > 3;
      if (popularCategory === "All") return true;
      return ps;
    });
  };

  const handlePopularCategory = (event, newValue) => {
    setPopularCategory(newValue);
  };

  return {
    handlePopularCategory,
    games: getGames(),
    allGames: gameHome,
    gameLoading: gameHome.loading,
    popularCategory,
    featuredContentCards: gameHome.contentCard.featuredContentCards,
    bottomContentCards: gameHome.contentCard.bottomContentCards,
    isLoggedIn,
    isSwopstakesWeb,
  };
}
